<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.adminUser.list')"
    class="px-5 py-3"
  >
    <v-col cols="12" class="text-right add--button" v-if="permissions.includes('ADD')">
      <v-btn color="success" class="mr-0" @click="openAdminForm">
        {{ $t('adminUser.add') }}
      </v-btn>
    </v-col>
    <div class="mt-2" v-if="meta && meta.pagination">
      {{ $t('belirumaUser.viewTotal') }}: {{ meta.pagination.total }}
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('belirumaUser.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.name') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.email') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.role') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.createdAt') }}
          </th>
          <!--          <th class="text-center primary&#45;&#45;text">-->
          <!--            {{ $t('general.openDetail') }}-->
          <!--          </th>-->
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`admin-user-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.role }}</td>
          <td>{{ format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') }}</td>
          <!--          <td>-->
          <!--            <base-detail-button-->
          <!--              :permissions="permissions"-->
          <!--              :basePath="basePath"-->
          <!--              :id="item.uuid"-->
          <!--            ></base-detail-button>-->
          <!--          </td>-->
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta.pagination"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta && meta.pagination"
    />
    <modal-admin></modal-admin>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');
const ModalAdmin = () => import('@/components/modals/modal-admin');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
    ModalAdmin,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.adminUser.basePath,
    }),
  },
  data() {
    return {
      format,
    };
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    openAdminForm() {
      this.$modal.show('modal-admin', {
        formType: 'ADD',
        commitString: null,
        dispatchString: 'adminUser/form/postData',
        dispatchAfterString: 'adminUser/getInitData',
      });
    },
  },
};
</script>
